import Service from "../Service";
import Vue from "vue";

const resource = "AsignmentSuperviceForeman/";

export default {
    save(prd, requestID) {
      return Service.post(resource + "saveS", prd, {
        params: { requestID: requestID },
      });
    },

    pagination(parameters, requestID) {
      return Service.post(resource + "pagination", parameters, {
        params: {requestID: requestID}
      });
    },

    workerslist(AsfID, Search, requestID)
    {
      return Service.post(resource + 'listworkers',{}, {
        params: {AsfID, Search, requestID: requestID}
      });
    }
};
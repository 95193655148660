<template scope="props">
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <s-crud
            title="Supervisores"
            :filter="filter"
            :config="configSupervisor"
            @rowSelected="rowSelected($event)"
            ref="itemsAsignation"
          >
          </s-crud>
        </v-col>
        <v-col cols="6">
          <s-toolbar
            remove
            color="primary"
            add
            dark
            @add="addCapataz()"
            @removed="removeCapataz()"
            label="Asignación Supervisor - Capataz"
          ></s-toolbar>
          <v-card>
            <v-col>
              <v-container>
                <v-row>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select-worker
                      label="Supervisor"
                      v-model="ObjSupervice"
                      :typepayrollallow="TypePayroll"
                      return-object
                      :text="supervisorName"
                    >
                    </s-select-worker>
                  </v-col>
                  <v-col lg="6" class="s-col-form" cols="12">
                    <s-select-worker
                      label="Capataz"
                      v-model="ObjForever"
                      :typepayrollallow="TypePayroll"
                      return-object
                    >
                    </s-select-worker>
                  </v-col>
                  <v-col>
                    <v-data-table
                      dense
                      v-model="selectedAsignation"
                      :headers="headers"
                      :items="itemsAsignation"
                      single-select
                      item-key="AsdIDM"
                      show-select
                      class="elevation-1"
                      ref="pendejo"
                    >
                    </v-data-table>
                  </v-col>
                  <v-container>
                    <v-row justify="center">
                      <v-col lg="6">
                        <v-btn @click="clearOption()" small block
                          ><v-icon x-small left>fas fa-undo-alt</v-icon
                          >Limpiar</v-btn
                        >
                      </v-col>
                      <v-col lg="6">
                        <v-btn small block color="primary " @click="save()">
                          <v-icon small left>fas fa-save</v-icon> Guardar</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-row>
              </v-container>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import _SuperviceForeman from "@/services/Technicalassistance/AsignmentSuperviceForeman";
import sSelectWorker from "@/components/Utils/Worker/sSelectWorker";
export default {
  components: { sSelectWorker },
  props: {
    TypePayroll: { default: 0 },
  },
  mounted() {},
  data: () => ({
    AsfID: null,
    CodAsig:null,
    ObjPrueba: {},
    supervisorName: "",
    props: {},
    selectedAsignation: [],
    itemsAsignation: [],
    DetailSuperviceForeman: [],
    DataWorker: {},
    ObjForever: null,
    ObjSupervice: null,
    headers: [
      { text: "SupervisorID", value: "NtpSuperviceID" },
      { text: "CapatazID", value: "NtpForemanID" },
      { text: "Capataz", value: "WkrName" },
    ],
    config: {
      service: _SuperviceForeman,
    },
    configSupervisor: {
      model: {
        CtrID: "ID",
        SupervisorName: "string",
      },
      service: _SuperviceForeman,
      headers: [
        { text: "ID", value: "CtrID" },
        { text: "Supervisor", value: "SupervisorName" },
      ],
    },
  }),

  methods: {
    initialize() {},
    addCapataz() {
      //console.log("Addd", this.ObjSupervice);
      let obj = {
        NtpSuperviceID: this.ObjSupervice.NtpID,
        NtpForemanID: this.ObjForever.NtpID,
        WkrName: this.ObjForever.WkrName,
        //DNI:this.ObjForever.PrsDocumentNumber
        //NtpID, referencia tabla GenNaturanPerson y esta tabla encontrarás PrsID, que hace referencia GenPerson
      };
      this.itemsAsignation.push(obj);
      this.clearForm();
    },

    removeCapataz() {
      this.selectedAsignation.forEach((element) => {
        this.itemsAsignation = this.itemsAsignation.filter(
          (x) => x.AsdIDM != element.AsdIDM
        );
      });
    },

    clearOption() {
      this.itemsAsignation = [];
      this.ObjSupervice = null;
    },

    save() {
      /* DetailSuperviceForeman = this.itemsAsignation;
        if(this.validationForm())
        save();*/
        console.log("IDDD",this.CodAsig)
        console.log("INSERT",this.itemsAsignation)
        //this.itemsAsignation.itemsAsignation= this.AsfID;
      if (this.itemsAsignation == null) {
        this.$fun.alert("Grilla Vacía", "Warning");
        return;
      }
      for (let i = 0; i < this.itemsAsignation.length; i++) {
        if (this.itemsAsignation[i].NtpForemanID == null) {
          this.$fun.alert(
            "Falta DNI del Capataz," + this.selectedAsignation[i].NtpForemanID,
            "Warning"
          );
          return;
        }
      }
      this.$fun.alert("Seguro de Guardar", "question").then((r) => {
        if (r.value) {
          _SuperviceForeman.save(this.itemsAsignation, this.$fun.getUserID())
            .then((response) => {
              this.$fun.alert("Guardado Correctamente", "success");
              this.$refs.itemsAsignation.refresh();
            });
        }
      });
    },

    rowSelected(item) {
      console.log("",item)
      if (item.length == 0 || !item) return;
      this.supervisorName = item[0].SupervisorName;
      //this.supervisorName = item[0].SupervisorName;
     this.AsfID = item[0].NtpSuperviceID;
      //console.log(item[0]);
      let Search = "";
      _SuperviceForeman
        .workerslist(this.AsfID, Search, this.$fun.getUserID())
        .then((r) => {
          if (r.status == 200) {
            this.itemsAsignation = [];
            r.data.forEach((element) => {
              let obj = {
                NtpForemanID: element.NtpForemanID,
                NtpSuperviceID: element.NtpSuperviceID,
                //NtpID: element.NtpID,
                WkrName: element.Worker,
                //AsdIDM: element.AsdIDM,
                AsfID: element.AsfID,
              };
              this.itemsAsignation.push(obj);
            });
          }
        });
    },

    clearForm() {
      this.ObjForever = null;
    },
    validationForm(item) {
      return true;
    },
  },

  created() {
    this.filter = {};
    //this.supervisorName = item[0].SupervisorName;
  },
};
</script>
